<template>
    <div class="pay_jianmian">
        <!-- 减免 -->
        <myDrawer :visible="showDrawer" :width="68" @close="close" :bodyStyle="{padding:0}">
            <div class="xianjin">
                <div class="title">
                    <div class="back" @click="goSettlePage">
                        <span class="iconfont icon-fanhui" v-if="!showSuccess"></span>
                        <div v-if="!showSuccess">返回结算页</div>
                    </div>
                    <div class="title_name">减免</div>
                    <div style="width: 20vw;"></div>
                </div>
                <!-- 支付成功 -->
                <!-- <div class="success_box" v-if="showSuccess">
                    <span class="iconfont icon-xiaolian" :style="completePay ? 'color: #28ac3c;' : 'color:#2a82e4'"></span>
                    <div class="payMoney">余额支付￥119.00</div>
                    <div class="wancheng" v-if="completePay">完成结算</div>
                    <div v-else class="last">
                        <div class="xiaoji">
                            <div>
                                <div>应收</div>
                                <div class="xiaoji1">￥120.00</div>
                            </div>
                            <div>
                                <div>代收</div>
                                <div class="xiaoji2">￥120.00</div>
                            </div>
                            <div>
                                <div>优惠</div>
                                <div class="xiaoji3">￥120.00</div>
                            </div>
                        </div>
                        <div class="last_btn">
                            <a-button class="btn" @click="goSettlePage">返回结算页</a-button>
                            <a-button class="btn">扫码支付</a-button>
                        </div>
                    </div>
                </div> -->
                <!-- 支付 -->
                <div class="right_box">
                    <div class="xiaoji checkedbgc">
                        <div>
                            <div>应收</div>
                            <div class="xiaoji1">￥{{orderInfo.order_price}}</div>
                        </div>
                        <div>
                            <div>待收</div>
                            <div class="xiaoji2">￥{{orderInfo.nopay_price}}</div>
                        </div>
                        <div>
                            <div>优惠</div>
                            <div class="xiaoji3">￥{{(orderInfo.total_price - orderInfo.order_price).toFixed(2)}}</div>
                        </div>
                    </div>
                    <div class="input_box">
                        <div class="inp_box">
                            <span>减免</span>
                            <a-input class="inp" v-model="jianmian" placeholder="请输入减免金额" @pressEnter="okHandle"/>
                        </div>
                    </div>
                    <div :style="'height:' + height + 'vh;margin-top:10vh;'">
                        <numCode :height="height" v-model="jianmian" @okHandle="okHandle" :showMoney="true"></numCode>
                    </div>
                </div>
            </div>
        </myDrawer>
    </div>
</template>
<script>
import myDrawer from '@/components/my-drower/my-drawer.vue'
import numCode from '@/components/keycode/fastFoodNumCode.vue'
import { mapState , mapMutations } from "vuex";
import { orderFree } from "@/request/fast_food_settle";
export default {
    name:'pay_jianmian',
    components:{ myDrawer , numCode },
    props:{
        showDrawer:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            jianmian:'',
            showSuccess:false,
            completePay:false,
            height:48,
            click_num:0,
        }
    },
    watch:{
        showDrawer(val){
            if(val){
                this.jianmian = this.orderInfo.free_price
            }else{
                this.showSuccess = false
            }
        },
        jianmian(val){
            if(val * 1 > this.orderInfo.nopay_price * 1){
                this.jianmian = val.slice(0,val.length - 1)
                this.$message.warning('减免金额不能大于待收金额')
            }else{
                this.$set(this , 'jianmian' , this.getTofixed(val))
            }
            this.focus_select = false
        }
    },
    computed:{
        ...mapState({
            orderInfo:state=>state.fast_food.orderInfo,
        }),
    },
    methods:{
        ...mapMutations(['set_orderInfo']),
        close(){
            this.$emit('closeHandle')
        },
        okHandle(){
            // this.showSuccess = true
            if(this.jianmian){
                orderFree({
                    founding_log_id:this.orderInfo.foundingLogId,
                    free_price:this.jianmian
                }).then(res => {
                    if(res.data.status === 200){
                        this.goSettlePage()
                    }
                })
            }else{
                this.$message.error('请输入减免金额')
            }
        },
        goSettlePage(){
            this.showSuccess = false
            this.$emit('goSettlePage', 'jianmian')
        },
    }
}
</script>
<style lang="less" scoped>
.xianjin{
    height: 100vh;
    font-size: 1.8vh;
    color: #505050;
    .title{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e5e5e5;
        height: 8vh;
        line-height: 8vh;
        .back{
            width: 20vw;
            margin-left: 2vw;
            color: #d95e58;
            vertical-align: middle;
            display: flex;
            align-items: center;
            cursor: pointer;
            span{
                font-size: 2.8vh;
                margin-right: 1vw;
            }
        }
        .title_name{
            font-size: 2.2vh;
            font-weight: 700;
            text-align: center;
        }
        
        
    }
    .success_box{
        padding: 6vh 9vw;
        text-align: center;
        .icon-xiaolian{
            font-size: 15vh;
        }
        .payMoney{
            font-size: 3vh;
        }
        .wancheng{
            background: #d95e58;
            color: #ffffff;
            width: 30vw;
            margin: 20vh auto 0;
            height: 8vh;
            line-height: 8vh;
            font-size: 3vh;
            border-radius: 0.6vh;
        }
        .last{
            margin-top: 15vh;
            .xiaoji{
                // background: #ffe8e8;
                display: flex;
                padding: 3vh 2vw;
                font-weight: 700;
                font-size: 2vh;
                border-radius: 0.6vh;
                >div{
                    flex:1;
                    text-align: center;
                    .xiaoji1,.xiaoji2,.xiaoji3{
                        font-size: 2.5vh;
                        color: #57d63f;
                        margin-top: 2vh;
                    }
                    .xiaoji1,.xiaoji2{
                        border-right: 1px solid #e5e5e5;
                        color: #ff6565;
                    }
                    .xiaoji1{
                        color: #808080;
                    }
                }
            }
            .last_btn{
                margin-top: 5vh;
                .btn, .btn:active, .btn:focus, .btn:hover{
                    height: 6vh;
                    line-height: 6vh;
                    width: 24vw;
                    border-color: #ff6565;
                    color: #505050;
                    &:first-child{
                        margin-right: 2vw;
                    }
                }
            }
        }
    }
    .right_box{
        padding: 3vh 1.5vw;
        .xiaoji{
            // background: #ffe8e8;
            display: flex;
            padding: 3vh 2vw;
            font-weight: 700;
            font-size: 2.5vh;
            border-radius: 0.6vh;
            >div{
                flex:1;
                text-align: center;
                .xiaoji1,.xiaoji2,.xiaoji3{
                    font-size: 3.8vh;
                    color: #57d63f;
                    margin-top: 2.6vh;
                }
                .xiaoji1,.xiaoji2{
                    border-right: 1px solid #e5e5e5;
                    color: #ff6565;
                }
                .xiaoji1{
                    color: #808080;
                }
            }
        }
        .input_box{
            font-size: 2.2vh;
            // display: flex;
            margin-top: 3vh;
            .inp_box{
                flex: 1;
                border: 1px solid #a6a6a6;
                display: flex;
                height: 7vh;
                padding: 1.5vh 1vw;
                border-radius: 0.6vh;
                line-height: 4vh;
                >span{
                    width: 10vw;
                    border-right: 1px solid #a6a6a6;
                    text-align: center;
                    padding-right: 1vw;
                }
                .inp , .inp:focus , .inp:active{
                    height: 4.2vh;
                    font-size: 2.8vh!important;
                    font-weight: 700;
                    color: #ff6565;
                    border: none;
                    outline:none;
                    resize:none;
                    margin-left: 2vw;
                    &::-webkit-input-placeholder{
                        color: #ff6565;
                        font-weight: normal;
                    }
                }
                >b{
                    font-size: 2.8vh;
                    color: #2a82e4;
                }
            }
        }
        .img_box{
            img{
                width: 100%;
            }
        }
    }
}
</style>