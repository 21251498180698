<template>
    <div class="changePrice">
        <!-- 改价 -->
        <a-modal :footer="null" :title="'改价：' + changeGoods.goods_name" :visible="showModal" @cancel="handleCancel" width="50vw">
            <div class="changePrice_box">
                <div class="title">商品基本信息</div>
                <div class="row">
                    <div class="left">原价：</div>
                    <div class="right"><b>￥{{changeGoods.goods_original_price}}</b></div>
                </div>
                <div class="row">
                    <div class="left">计量：</div>
                    <div class="right"><b>{{changeGoods.total_num}}{{changeGoods.is_weighing == 2 ? '  KG' : ''}}</b></div>
                </div>
                <div class="row">
                    <div class="left">当前单价：</div>
                    <div class="right"><a-input class="inp" ref="input" v-model="thisPrice" prefix="￥" @pressEnter="okHandle"
                     @select="focus_select = true" @click="focus_select = false" @focus="focushandle"/></div>
                </div>
                <div :style="'height:' + height + 'vh;margin-top:5vh;'">
                    <numCode :height="height" v-model="thisPrice" @okHandle="okHandle" :focus_select="focus_select"></numCode>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import numCode from '@/components/keycode/fastFoodNumCode.vue'
import { mapState , mapMutations } from "vuex";
export default {
    name:'changePrice',
    props:{
        showModal:{
            default:false,
            type:Boolean
        },
        activeGoods:{
            default:0,
            type:Number
        }
    },
    components:{ numCode },
    data(){
        return{
            height:32,
            thisPrice:'',
            changeGoods:{},
            focus_select:false
        }
    },
    watch:{
        showModal(val){
            if(val){
                this.changeGoods = this.cartList[this.activeGoods]
                this.thisPrice = this.changeGoods.goods_price
                setTimeout(() => {
                    this.focushandle()
                },300)
                
            }else{
                this.thisPrice = ''
                this.changeGoods = {}
            }
        },
        thisPrice(val){
            this.$set(this , 'thisPrice' , this.getTofixed(val))
            this.focus_select = false
        }
    },
    computed: {
        ...mapState({
            cartList:state=>state.fast_food.cartList,
        }),
    },
    methods:{
        ...mapMutations(['set_cartList']),
        handleCancel(){
            this.$emit('closeHandle')
        },
        okHandle(){
            console.log(this.thisPrice);
            if(this.thisPrice){
                let list = JSON.parse(JSON.stringify(this.cartList))
                list[this.activeGoods].goods_price = this.thisPrice
                this.set_cartList(list)
                this.handleCancel()
            }else{
                this.$message.error('请输入金额')
            }
        },
        focushandle(){
            this.$refs.input.select();
        }
    }
}
</script>
<style lang="less" scoped>
.changePrice_box{
    .title{
        font-weight: 700;
        font-size: 2.5vh;
        margin-bottom: 2vh;
    }
    .row{
        display: flex;
        align-items: center;
        margin-bottom: 2vh;
        font-size: 2.2vh;
        .left{
            flex: 1;
        }
        .right{
            flex: 4;
        }
    }
    ::v-deep .ant-input-affix-wrapper{
        height: 4.2vh;
        .ant-input-prefix{
            font-size: 3vh;
            margin: 0.5vh 0;
        }
        .ant-input{
            height: 5.2vh;
            padding-left: 4vw;
            font-size: 2.5vh!important;
        }
    }
}
</style>