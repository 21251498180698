<template>
    <div class="pending">
        <!-- 取单 -->
        <a-modal :footer="null" title="取单" :visible="showModal" @cancel="handleCancel" width="80vw">
            <div class="pending_box">
                <div class="left">
                    <div :class="active == i ? 'user user_active' : 'user'" v-for="(item,i) in pendingOrder" :key="i" @click="tableData = item.list;active = i">
                        <div>
                            <b v-if="item.orderInfo && item.orderInfo.orderUser && item.orderInfo.orderUser.mobile">({{i+1}}) {{item.orderInfo.orderUser.nick_name || item.orderInfo.orderUser.mobile}}</b>
                            <b v-else>({{i+1}})</b>
                            <div class="time">{{item.longTime}}前（共{{item.total_num}}件）</div>
                        </div>
                        <b>￥{{item.total_price}}</b>
                    </div>
                </div>
                <div class="right">
                    <div class="goods_list">
                        <div class="title">商品列表</div>
                        <a-table :columns="columns" :data-source="tableData" :pagination="{defaultPageSize:5}" :rowKey="(item,index) => index">
                            <span slot="goods_price" slot-scope="text,record">
                                {{Number(record.total_num) * Number(record.goods_price)}}
                            </span>
                        </a-table>
                    </div>
                    <div class="btn_box">
                        <a-button class="clearBtn textColor" @click="zuofei">整单作废</a-button>
                        <a-button class="OkBtn" @click="qudan">取单</a-button>
                    </div>
                </div>
            </div>
        </a-modal>
        <orderInvalid :showModal="showInvalid" @closeHandle="closeInvalid" :fromPage="fromPage" @closeOrder="closeOrder" @voidOrder="voidOrder"></orderInvalid>
    </div>
</template>
<script>
import { mapState , mapMutations } from "vuex";
import orderInvalid from './orderInvalid.vue'
export default {
    name:'pending',
    props:{
        showModal:{
            default:false,
            type:Boolean
        }
    },
    components:{ orderInvalid },
    data(){
        return{
            columns:[
                {
                    title: '商品名称',
                    dataIndex: 'goods_name',
                },
                {
                    title: '数量',
                    dataIndex: 'total_num',
                },
                {
                    title: '小计',
                    dataIndex: 'goods_price',
                    scopedSlots: { customRender: 'goods_price' }
                }
            ],
            tableData:[],
            showInvalid:false,
            fromPage:'pending',
            active:0
        }
    },
    watch:{
        showModal(val){
            if(val){
                let pendingList = JSON.parse(JSON.stringify(this.pendingOrder))
                pendingList.forEach(item => {
                    let time = parseInt((+new Date() - item.time) / 1000)
                    let h = Math.floor(time / 3600)
                    let m = Math.floor((time / 60 % 60))
                    let s = Math.floor((time % 60))
                    item.longTime = (h ? h + '小时' : '') + (m || h ? m + '分' : '') + s + '秒'
                    let num = 0
                    let price = 0
                    if(item.list && item.list.length){
                        item.list.forEach(item => {
                            num += Number(item.total_num)
                            price += Number(item.total_num) * Number(item.goods_price)
                        });
                    }
                    item.total_num = num
                    item.total_price = price
                })
                this.set_pendingOrder(pendingList)
                this.tableData = pendingList[0].list
                this.active = 0
            }
        }
    },
    computed: {
        ...mapState({
            cartList:state=>state.fast_food.cartList,
            pendingOrder:state=>state.fast_food.pendingOrder,
        })
    },
    mounted(){

    },
    methods:{
        ...mapMutations(['set_cartList','set_pendingOrder','set_orderInfo','set_currentMember']),
        handleOk(){

        },
        handleCancel(){
            this.$emit('closeHandle')
        },
        qudan(){
            let item = this.pendingOrder[this.active]
            this.set_cartList(item.list)
            if(item.orderInfo && item.orderInfo.order_no){
                this.set_orderInfo(item.orderInfo)
                if(item.orderInfo && item.orderInfo.orderUser && item.orderInfo.orderUser.mobile){
                    this.set_currentMember(item.orderInfo.orderUser)
                }
            }
            let pendingList = JSON.parse(JSON.stringify(this.pendingOrder))
            pendingList.splice(this.active , 1)
            this.set_pendingOrder(pendingList)
            this.handleCancel()
        },
        zuofei(){
            let item = this.pendingOrder[this.active]
            if(item.orderInfo && item.orderInfo.order_no){
                this.set_orderInfo(item.orderInfo)
            }
            this.showInvalid = true
        },
        closeInvalid(){
            this.showInvalid = false
            this.set_orderInfo({})
        },
        closeOrder(){
            this.showInvalid = false
            // this.$emit('closeHandle')
        },
        voidOrder(){
            let pendingList = JSON.parse(JSON.stringify(this.pendingOrder))
            pendingList.splice(this.active , 1)
            this.set_pendingOrder(pendingList)
            if(pendingList[this.active] && pendingList[this.active].time){
                this.tableData = pendingList[this.active].list
            }else{
                if(this.active > 0){
                    this.tableData = pendingList[this.active - 1].list
                    this.active -= 1
                }else{
                    this.tableData = []
                    this.handleCancel()
                }
            }
            
        }
    }
}
</script>
<style lang="less" scoped>
.pending_box{
    height: 69vh;
    display: flex;
    background: #ecedf3;
    .left{
        width: 20vw;
        background: #ffffff;
        margin-right: 1vw;
        padding: 0.5vh 0;
        font-size: 2vh;
        .user{
            padding: 1vh 1vw;
            display: flex;
            justify-content: space-between;
            align-items: center;
            line-height: 3.5vh;
            color: #505050;
            cursor: pointer;
            border-bottom: 1px solid #f3d5d5;
            .time{
                font-size: 1.7vh;
            }
            &:hover{
                background: #f3d5d5;
            }
        }
        .user_active{
            background: #f3d5d5;
        }
    }
    .right{
        flex: 1;
        .goods_list{
            height: 59.5vh;
            background: #ffffff;
            margin-bottom: 1.5vh;
            padding: 1vh 1vw;
            overflow: auto;
            .title{
                font-weight: 700;
                font-size: 2.5vh;
                margin-bottom: 1vh;
            }
        }
        .btn_box{
            height: 8vh;
            background: #ffffff;
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 1vh 1vw;
        }
    }
}
::v-deep .ant-modal-body{
    padding: 0;
}
::v-deep .ant-table-body{
    th , td {
        padding: 2vh 2vw;
        font-size: 2vh;
    }
}
</style>