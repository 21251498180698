<template>
    <div class="orderRemark">
        <!-- 整单备注 -->
        <a-modal title="整单备注" :visible="showModal" @cancel="handleCancel" width="50vw">
            <div class="remark">
                <div class="left">备注内容：</div>
                <div class="right">
                    <a-textarea placeholder="请输入备注内容" v-model="remark" :rows="4" />
                </div>
            </div>
            <template slot="footer">
                <div class="bottomBtn" >
                    <a-button class="clearBtn btn" @click="handleCancel">取消</a-button>
                    <a-button class="OkBtn btn" @click="handleOk">确定</a-button>
                </div>
            </template>
        </a-modal>
    </div>
</template>
<script>
import { OrderDetail , orderRemark } from "@/request/fast_food_settle";
import { mapState , mapMutations } from "vuex";
export default {
    name:'orderRemark',
    props:{
        showModal:{
            default:false,
            type:Boolean
        },
    },
    data(){
        return{
            remark:''
        }
    },
    watch:{
        showModal(val){
            if(val){
                let remarkList = JSON.parse(this.orderInfo.buyer_remark)
                if(remarkList && remarkList.length){
                    this.remark = remarkList[remarkList.length - 1]
                }
            }
        }
    },
    computed:{
        ...mapState({
            orderInfo:state=>state.fast_food.orderInfo,
        }),
    },
    methods:{
        ...mapMutations(['set_orderInfo']),
        handleOk(){
            if(this.remark){
                orderRemark({
                    order_id:this.orderInfo.order_id,
                    buyer_remark:this.remark
                }).then(res => {
                    if(res.data.status === 200){
                        this.getOrderDetail()
                    }
                })
            }else{
                this.$message.warning('请输入备注')
            }
        },
        getOrderDetail(){
            OrderDetail({foundingLoId : this.orderInfo.foundingLogId}).then(res => {
                if(res.data.status === 200){
                    this.set_orderInfo({
                        ...res.data.data,
                        foundingLogId:res.data.data.founding_log_id
                    })
                    this.handleCancel()
                }
            })
        },
        handleCancel(){
            this.$emit('closeHandle')
        },

    }
}
</script>
<style lang="less" scoped>
.remark{
    display: flex;
    .left{
        width: 10vw;
        font-size: 2.2vh;
        color: #505050;
    }
    .right{
        flex: 1;
        textarea{
            font-size: 2.2vh;
        }
    }
}
.bottomBtn{
    padding: 1vh 8vw;
    display: flex;
    justify-content: space-around;
    .btn{
        width: 14vw;
        font-weight: 700;
    }
}
::v-deep .ant-modal-close-x .anticon svg{
    color: #d95e58;
}
</style>