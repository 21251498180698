<template>
    <div class="weighing">
        <!-- 商品扫描提示 -->
        <a-modal  title="商品扫描提示" :visible="showModal" width="58vw" @cancel="handleCancel">
            <div class="content">未查询到该商品信息！是否立即添加！</div>
            <template slot="footer">
                <a-button class="clearBtn textColor" @click="handleCancel">取消</a-button>
                <a-button  class="OkBtn" @click="handleOk">立即添加</a-button>
            </template>
        </a-modal>
    </div>
</template>
<script>
export default {
    name:'weighing',
    props:{
        showModal:{
            default:false,
            type:Boolean
        },
        activeGoods:{
            default:0,
            type:Number
        }
    },
    data(){
        return{
        }
    },
    watch:{
        showModal(val){
            if(val){
               
            }
        }
    },
   
    methods:{
        handleCancel(){
            this.$emit('closeScanTip')
        },
       handleOk(){
          this.$emit('closeScanOk')
       }
    }
}
</script>
<style lang="less" scoped>
/deep/.ant-modal-header{
    padding:20px 24px;
    .ant-modal-title{
        text-align: center;
        font-weight: bold;
        font-size: 3.5vh;
    }
}
.content{
    font-size: 1.8vh;
    line-height:16.5vh;
    color:#505050;
}
/deep/.ant-modal-body{
    height:16.5vh;
    padding:0 24px;
}
/deep/.ant-modal-footer{
    text-align: center;
}
.OkBtn,.clearBtn{
    width:11.1vw;
    padding:0;
}
.OkBtn{
    margin-left: 2.6vw;
}
   
</style>