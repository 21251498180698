<template>
    <div class="pay_xianjin">
        <!-- 现金结算 -->
        <myDrawer :visible="showDrawer" :width="68" @close="close" :bodyStyle="{padding:0}">
            <div class="xianjin">
                <div class="title">
                    <div class="back" @click="goSettlePage">
                        <span class="iconfont icon-fanhui" v-if="!showSuccess"></span>
                        <div v-if="!showSuccess">返回结算页</div>
                    </div>
                    <div class="title_name">现金支付</div>
                    <div style="width: 20vw;"></div>
                </div>
                <!-- 支付成功 -->
                <div class="success_box" v-if="showSuccess">
                    <span class="iconfont icon-xiaolian" :style="pay_Finish ? 'color: #28ac3c;' : 'color:#2a82e4'"></span>
                    <div class="payMoney">现金支付￥{{Number(current_price).toFixed(2)}}</div>
                    <div class="wancheng bgColor" v-if="pay_Finish">完成结算</div>
                    <div v-else class="last">
                        <div class="xiaoji checkedbgc">
                            <div>
                                <div>应收</div>
                                <div class="xiaoji1">￥{{orderInfo.order_price}}</div>
                            </div>
                            <div>
                                <div>待收</div>
                                <div class="xiaoji2">￥{{orderInfo.nopay_price}}</div>
                            </div>
                            <div>
                                <div>优惠</div>
                                <div class="xiaoji3">￥{{(orderInfo.total_price - orderInfo.order_price).toFixed(2)}}</div>
                            </div>
                        </div>
                        <div class="last_btn">
                            <a-button class="btn" @click="goSettlePage">返回结算页</a-button>
                            <a-button class="btn" @click="goSettlePage('saoma')">扫码支付</a-button>
                        </div>
                    </div>
                </div>
                <!-- 支付 -->
                <div class="right_box" v-else>
                    <div class="xiaoji checkedbgc">
                        <div>
                            <div>应收</div>
                            <div class="xiaoji1">￥{{orderInfo.order_price}}</div>
                        </div>
                        <div>
                            <div>待收</div>
                            <div class="xiaoji2">￥{{orderInfo.nopay_price}}</div>
                        </div>
                        <div>
                            <div>优惠</div>
                            <div class="xiaoji3">￥{{(orderInfo.total_price - orderInfo.order_price).toFixed(2)}}</div>
                        </div>
                    </div>
                    <div class="input_box">
                        <div class="inp_box">
                            <span>现金：</span>
                            <a-input class="inp" ref="input" v-model="money" @pressEnter="okHandle" @select="focus_select = true" @click="focus_select = false" @focus="focushandle"/>
                        </div>
                        <div class="inp_box">
                            <span>找零：</span>
                            <b>{{zhaoling}}</b>
                        </div>
                    </div>
                    <div :style="'height:' + height + 'vh;margin-top:10vh;'">
                        <numCode :height="height" v-model="money" @okHandle="okHandle" :showMoney="true" :focus_select="focus_select"></numCode>
                    </div>
                </div>
            </div>
        </myDrawer>
    </div>
</template>
<script>
import myDrawer from '@/components/my-drower/my-drawer.vue'
import numCode from '@/components/keycode/fastFoodNumCode.vue'
import { mapState , mapMutations } from "vuex";
import { orderpay , orderpaystatus , OrderDetail , finish } from "@/request/fast_food_settle";
export default {
    name:'pay_xianjin',
    components:{ myDrawer , numCode },
    props:{
        showDrawer:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            money:'',
            showSuccess:false,
            completePay:false,
            height:48,
            zhaoling:0,
            current_price:0,
            focus_select:false,
            pay_Finish:false
        }
    },
    watch:{
        showDrawer(val){
            if(val){
                this.money = this.orderInfo.nopay_price
            }else{
                this.showSuccess = false
                this.pay_Finish = false
            }
        },
        money(val){
            this.zhaoling = (this.money - this.orderInfo.nopay_price).toFixed(2) > 0 ? (this.money - this.orderInfo.nopay_price).toFixed(2) : 0
            this.$set(this , 'money' , this.getTofixed(val))
            this.focus_select = false
        }
    },
    computed:{
        ...mapState({
            orderInfo:state=>state.fast_food.orderInfo,
        }),
    },
    methods:{
        ...mapMutations(['set_orderInfo','set_currentMember','set_cartList']),
        close(){
            this.$emit('closeHandle')
        },
        okHandle(){
            if(this.money){
                this.current_price = Number(this.money) > Number(this.orderInfo.nopay_price) ? this.orderInfo.nopay_price : this.money
                orderpay({
                    foundingLoId:this.orderInfo.foundingLogId,
                    pay_type:'cash',
                    auth_no:'',
                    current_price:this.current_price
                }).then(res => {
                    if(res.data.status === 200){
                        this.getStatus(res.data.data)
                    }
                })
            }else{
                this.$message.error('请输入支付金额')
            }
            
        },
        getStatus(data){
            orderpaystatus(data).then(res => {
                if(res.data.status === 200){
                    if(res.data.data.data_state == 2){
                        this.getOrderDetail()
                    }else{
                        setTimeout(() => {
                            this.getStatus(data)
                        },500)
                    }
                }
            })
        },
        getOrderDetail(){
            OrderDetail({foundingLoId : this.orderInfo.foundingLogId}).then(res => {
                if(res.data.status === 200){
                    this.set_orderInfo({
                        ...res.data.data,
                        foundingLogId:res.data.data.founding_log_id
                    })
                    this.showSuccess = true
                    if(this.orderInfo.nopay_price == 0){
                        // setTimeout(() => {
                        //     this.FinishOrder()
                        // },500)
                        this.pay_Finish = true
                        this.$message.success('结算完成')
                        this.$emit('FinishOrder')
                    }
                }
            })
        },
        // FinishOrder(){
        //     finish({foundingLoId:this.orderInfo.foundingLogId}).then(res => {
        //         if(res.data.status === 200){
        //             this.$message.success('结算完成')
        //             this.$emit('FinishOrder')
        //             this.showSuccess = false
        //         }
        //     })
        // },
        goSettlePage(str){
            this.showSuccess = false
            this.$emit('goSettlePage', 'xianjin',str)
        },
        focushandle(){
            this.$refs.input.select();
        }
    }
}
</script>
<style lang="less" scoped>
.xianjin{
    height: 100vh;
    font-size: 1.8vh;
    color: #505050;
    .title{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e5e5e5;
        height: 8vh;
        line-height: 8vh;
        .back{
            width: 20vw;
            margin-left: 2vw;
            color: #d95e58;
            vertical-align: middle;
            display: flex;
            align-items: center;
            cursor: pointer;
            span{
                font-size: 2.8vh;
                margin-right: 1vw;
            }
        }
        .title_name{
            font-size: 2.2vh;
            font-weight: 700;
            text-align: center;
        }
        
        
    }
    .success_box{
        padding: 6vh 9vw;
        text-align: center;
        .icon-xiaolian{
            font-size: 15vh;
        }
        .payMoney{
            font-size: 3vh;
        }
        .wancheng{
            // background: #d95e58;
            color: #ffffff;
            width: 30vw;
            margin: 20vh auto 0;
            height: 8vh;
            line-height: 8vh;
            font-size: 3vh;
            border-radius: 0.6vh;
        }
        .last{
            margin-top: 15vh;
            .xiaoji{
                // background: #ffe8e8;
                display: flex;
                padding: 3vh 2vw;
                font-weight: 700;
                font-size: 2vh;
                border-radius: 0.6vh;
                >div{
                    flex:1;
                    text-align: center;
                    .xiaoji1,.xiaoji2,.xiaoji3{
                        font-size: 2.5vh;
                        color: #57d63f;
                        margin-top: 2vh;
                    }
                    .xiaoji1,.xiaoji2{
                        border-right: 1px solid #e5e5e5;
                        color: #ff6565;
                    }
                    .xiaoji1{
                        color: #808080;
                    }
                }
            }
            .last_btn{
                margin-top: 5vh;
                .btn, .btn:active, .btn:focus, .btn:hover{
                    height: 6vh;
                    line-height: 6vh;
                    width: 24vw;
                    border-color: #ff6565;
                    color: #505050;
                    font-size: 2vh;
                    &:first-child{
                        margin-right: 2vw;
                    }
                }
            }
        }
    }
    .right_box{
        padding: 3vh 1.5vw;
        .xiaoji{
            // background: #ffe8e8;
            display: flex;
            padding: 3vh 2vw;
            font-weight: 700;
            font-size: 2.5vh;
            border-radius: 0.6vh;
            >div{
                flex:1;
                text-align: center;
                .xiaoji1,.xiaoji2,.xiaoji3{
                    font-size: 3.8vh;
                    color: #57d63f;
                    margin-top: 2.6vh;
                }
                .xiaoji1,.xiaoji2{
                    border-right: 1px solid #e5e5e5;
                    color: #ff6565;
                }
                .xiaoji1{
                    color: #808080;
                }
            }
        }
        .input_box{
            font-size: 2.2vh;
            display: flex;
            margin-top: 3vh;
            .inp_box{
                flex: 1;
                border: 1px solid #a6a6a6;
                display: flex;
                height: 7vh;
                padding: 1.5vh 1vw;
                border-radius: 0.6vh;
                line-height: 4vh;
                &:last-child{
                    margin-left: 2vw;
                    background: #f8f8ff;
                }
                >span{
                    width: 8vw;
                }
                .inp , .inp:focus , .inp:active{
                    height: 4.2vh;
                    font-size: 2.8vh!important;
                    font-weight: 700;
                    color: #ff6565;
                    // text-align: center;
                    border: none;
                    outline:none;
                    resize:none;
                }
                >b{
                    font-size: 2.8vh;
                    color: #2a82e4;
                }
            }
            .input_r{
                color: #2a82e4;
                cursor: pointer;
            }
        }
        .img_box{
            img{
                width: 100%;
            }
        }
    }
}
</style>