<template>
    <div class="weighing">
        <!-- 称重 -->
        <a-modal :footer="null" :title="weightGoods.goods_name" :visible="showModal" @cancel="handleCancel" width="50vw" :maskClosable="false">
            <div class="changePrice_box">
                <div class="title">商品基本信息</div>
                <div class="row">
                    <div class="left">零售价：</div>
                    <div class="right"><b>￥{{weightGoods.goods_price}}</b></div>
                </div>
                <div class="row">
                    <div class="left">小计：</div>
                    <div class="right">{{(weightGoods.goods_price * (Number(weightNum) || 0)).toFixed(2)}}</div>
                </div>
                <div class="row">
                    <div class="left">重量：</div>
                    <div class="right"><a-input class="inp" v-model="weightNum" suffix="| KG" @pressEnter="okHandle"/></div>
                </div>
                <div :style="'height:' + height + 'vh;margin-top:5vh;'">
                    <numCode :height="height" v-model="weightNum" @okHandle="okHandle"></numCode>
                </div>
            </div>
        </a-modal>
    </div>
</template>
<script>
import numCode from '@/components/keycode/fastFoodNumCode.vue'
import { mapState , mapMutations } from "vuex";
export default {
    name:'weighing',
    props:{
        showModal:{
            default:false,
            type:Boolean
        },
        activeGoods:{
            default:0,
            type:Number
        }
    },
    components:{ numCode },
    data(){
        return{
            height:32,
            weightNum:'',
        }
    },
    watch:{
        showModal(val){
            if(val){
                 this.weightNum = '' 
            }
        },
        weightNum(val){
            this.$set(this , 'weightNum' , this.getTofixed(val))
        }
    },
    computed: {
        ...mapState({
            cartList:state=>state.fast_food.cartList,
            weightGoods:state=>state.fast_food.weightGoods,
        }),
    },
    methods:{
        ...mapMutations(['set_weightGoods','set_cartList']),
        handleCancel(){
            this.$emit('closeHandle')
        },
        okHandle(){
            if(this.weightNum){
                let weightGoods = JSON.parse(JSON.stringify(this.weightGoods))
                weightGoods.total_num = this.weightNum
                if(weightGoods.avoid || weightGoods.practice || weightGoods.taste){
                    this.set_weightGoods(weightGoods)
                    this.$emit('openGuige')
                }else{
                    let list = JSON.parse(JSON.stringify(this.cartList))
                    list.unshift(weightGoods)
                    this.set_cartList(list)
                    this.set_weightGoods({})
                    this.handleCancel()
                }
            }else{
                this.$message.error('请输入重量')
            }
        },
    }
}
</script>
<style lang="less" scoped>
.changePrice_box{
    .title{
        font-weight: 700;
        font-size: 2.5vh;
        margin-bottom: 2vh;
    }
    .row{
        display: flex;
        align-items: center;
        margin-bottom: 2vh;
        font-size: 2.2vh;
        .left{
            flex: 1;
        }
        .right{
            flex: 4;
        }
    }
    ::v-deep .ant-input-affix-wrapper{
        height: 4.2vh;
        .ant-input-suffix{
            font-size: 2.5vh;
            margin: 0.5vh 0;
        }
        .ant-input{
            height: 5.2vh;
            font-size: 2.5vh!important;
        }
    }
}
</style>