<template>
    <div class="settleHandle">
        <!-- 结算操作 -->
        <myDrawer :visible="showDrawer" :width="80" @close="close" :bodyStyle="{padding:0}">
            <div class="settle_box">
                <div class="left">
                    <div class="title">操作</div>
                    <div class="left_box">
                        <div class="modulesTitle">整单操作</div>
                        <div class="btn_box">
                            <a-button class="btn1" @click="showRemark = true">整单备注</a-button>
                            <a-button class="btn1" @click="showInvalid = true">整单作废</a-button>
                        </div>
                        <div class="modulesTitle">付款方式</div>
                        <div class="btn_box">
                            <a-button class="btn2" @click="handleOrder('saoma')">扫码付款</a-button>
                            <a-button class="btn2" @click="handleOrder('xianjin')">现金</a-button>
                        </div>
                        <div class="btn_box">
                            <a-button class="btn2" @click="handleOrder('huiyuan')">会员余额</a-button>
                            <!-- <a-button class="btn2" @click="handleOrder('weixin')">个人微信</a-button> -->
                        </div>
                        <!-- <div class="btn_box">
                            <a-button class="btn2" @click="handleOrder('zhifubao')">个人支付宝</a-button>
                        </div> -->
                        <div class="modulesTitle" v-if="permission.indexOf('/clerk/order/discount') > -1 && permission.indexOf('/clerk/order/free') > -1">优惠方式</div>
                        <div class="btn_box">
                            <div class="btn3_box" @click="handleOrder('zhekou')"  v-if="permission.indexOf('/clerk/order/discount') > -1">
                                <div class="jiaobiao" v-if="orderInfo.grade_ratio">
                                    <span>{{orderInfo.grade_ratio}}折</span>
                                </div>
                                <a-button class="btn3">自定义折扣</a-button>
                            </div>
                            <div class="btn3_box" @click="handleOrder('jianmian')" v-if="permission.indexOf('/clerk/order/free') > -1">
                                <div class="jiaobiao" v-if="orderInfo.free_price != 0">
                                    <span>免</span>
                                </div>
                                <a-button class="btn3">减免</a-button>
                            </div>
                        </div>
                    </div>
                    
                </div>
                <div class="right">
                    <div class="title">结账付款</div>
                    <div class="right_box">
                        <div class="xiaoji checkedbgc">
                            <div>
                                <div>应收</div>
                                <div class="xiaoji1">￥{{orderInfo.order_price}}</div>
                            </div>
                            <div>
                                <div>待收</div>
                                <div class="xiaoji2">￥{{orderInfo.nopay_price}}</div>
                            </div>
                            <div>
                                <div>优惠</div>
                                <div class="xiaoji3">￥{{(orderInfo.total_price - orderInfo.order_price).toFixed(2)}}</div>
                            </div>
                        </div>
                        <!-- <div class="check_box">
                            <a-checkbox v-model="xiaopiao">打印小票</a-checkbox>
                            <a-checkbox v-model="jiaohaodan">打印叫号单</a-checkbox>
                        </div> -->
                        <div class="member" v-if="currentMember && currentMember.user_id">
                            <div class="img">
                                <img :src="currentMember.avatar_url" v-if="currentMember.avatar_url != null" alt="">
                                <img src="../../../assets/default-avatar.png" v-else alt="">
                            </div>
                            <div class="userInfo">
                                <div class="photo">
                                    <b>{{currentMember.mobile}}</b>
                                    <span>{{currentMember.nick_name}}</span>
                                </div>
                                <div class="card_no">生日: {{currentMember.birthday}}</div>
                                <div class="card_no">卡号: {{currentMember.card_number}}</div>
                            </div>
                            <div class="btnHandle">
                                <a-button class="smallClearBtn" @click="cancelMember">取消会员</a-button><br>
                                <a-button class="smallClearBtn" @click="handleOrder('changeMember')">更换会员</a-button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </myDrawer>
        <orderInvalid :showModal="showInvalid" @closeHandle="showInvalid = false" @closeOrder="closeOrder"></orderInvalid>
        <orderRemark  :showModal="showRemark" @closeHandle="showRemark = false"></orderRemark>
    </div>
</template>
<script>
import myDrawer from '@/components/my-drower/my-drawer.vue'
import orderInvalid from './orderInvalid.vue'
import orderRemark from './orderRemark.vue'
import { mapState , mapMutations } from "vuex";
import { OrderDetail , orderUser } from "@/request/fast_food_settle";
export default {
    name:'settleHandle',
    components:{ myDrawer , orderInvalid , orderRemark},
    props:{
        showDrawer:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            xiaopiao:true,
            jiaohaodan:true,
            showInvalid:false,
            showRemark:false,
        }
    },
    watch:{
        showDrawer(val){
            if(val){
                this.getOrderDetail()
            }
        }
    },
    computed:{
        ...mapState({
            currentMember:state=>state.fast_food.currentMember,
            orderInfo:state=>state.fast_food.orderInfo,
            permission: state=>state.permission,
        }),
    },
    methods:{
        ...mapMutations(['set_currentMember','set_orderInfo']),
        getOrderDetail(){
            OrderDetail({foundingLoId : this.orderInfo.foundingLogId}).then(res => {
                if(res.data.status === 200){
                    this.set_orderInfo({
                        ...res.data.data,
                        foundingLogId:res.data.data.founding_log_id
                    })
                }
            })
        },
        close(){
            this.$emit('closeHandle')
        },
        handleOrder(type){
            if(type === 'huiyuan' && !(this.currentMember && this.currentMember.user_id)){
                this.$emit('handleOrder', 'checkMember')
            }else{
                this.$emit('handleOrder',type)
            }
            
        },
        cancelMember(){
            if(this.orderInfo && this.orderInfo.foundingLogId){
                orderUser({
                    keywords:this.currentMember.mobile,
                    founding_log_id:this.orderInfo.foundingLogId,
                    select_type:2
                }).then( res=> {
                    if(res.data.status == 200){
                        this.set_currentMember({})
                        this.getOrderDetail()
                    }
                })
            }else{
                this.set_currentMember({})
            }
        },
        closeOrder(){
            this.showInvalid = false 
            this.$emit('closeHandle')
        }
    }
}
</script>
<style lang="less" scoped>
.settle_box{
    display: flex;
    height: 100vh;
    font-size: #505050;
    .left{
        width: 30vw;
        height: 100%;
        border-right: 1px solid #e5e5e5;
        .title{
            font-size: 2.2vh;
            font-weight: 700;
            border-bottom: 1px solid #e5e5e5;
            text-align: center;
            height: 8vh;
            line-height: 8vh;
        }
        .left_box{
            padding: 2vh 1.5vw;
            .modulesTitle{
                margin: 0 0 2vh 0;
                font-size: 2vh;
                margin-top: 5vh;
                &:first-child{
                    margin-top: 1vh;
                }
            }
            .btn_box{
                display: flex;
                justify-content: space-between;
                .btn1,.btn2,.btn3 {
                    height: 6vh;
                    line-height: 6vh;
                    width: 12vw;
                    margin-bottom: 2vh;
                    font-size: 2vh;
                }
                .btn1, .btn1:active, .btn1:focus, .btn1:hover{
                    color: #2a82e4;
                    border-color: #a5a5a5;
                }
                .btn2, .btn2:active, .btn2:focus, .btn2:hover{
                    border-color: #ff6565;
                }

                .btn3 ,.btn3:active, .btn3:focus, .btn3:hover{
                    border-color: #a5a5a5;
                    color: #505505;
                }
                .btn3_box{
                    position: relative;
                    .jiaobiao{
                        position: absolute;
                        width: 0;
                        height: 0;
                        z-index: 9;
                        right: 0;
                        border-radius: 0.5vh;
                        border-top: 20px solid #ff6565;
                        border-left: 20px solid #ff656500;
                        border-right: 20px solid #ff6565;
                        border-bottom: 20px solid #ff656500;
                        span{
                            position: absolute;
                            width: 30px;
                            text-align: center;
                            transform: rotate(45deg);
                            right: -21px;
                            top: -16px;
                            font-size: 12px;
                            color: #ffffff;
                        }
                    }
                }
            }
        }
        
    }
    .right{
        flex: 1;
        height: 100%;
        .title{
            font-size: 2.2vh;
            font-weight: 700;
            border-bottom: 1px solid #e5e5e5;
            height: 8vh;
            line-height: 8vh;
            padding-left: 2vw;
        }
        .right_box{
            
            .xiaoji{
                margin: 2vh 1.5vw;
                // background: #ffe8e8;
                display: flex;
                padding: 3vh 2vw;
                font-weight: 700;
                font-size: 2vh;
                border-radius: 0.6vh;
                >div{
                    flex:1;
                    text-align: center;
                    .xiaoji1,.xiaoji2,.xiaoji3{
                        font-size: 2.5vh;
                        color: #57d63f;
                        margin-top: 2vh;
                    }
                    .xiaoji1,.xiaoji2{
                        border-right: 1px solid #e5e5e5;
                        color: #ff6565;
                    }
                    .xiaoji1{
                        color: #808080;
                    }
                }
            }
            .check_box{
                margin: 3vh 1.5vw;
                ::v-deep .ant-checkbox-checked .ant-checkbox-inner{
                    background: #ffffff;
                    border-color: #e5e5e5;
                }
                ::v-deep .ant-checkbox-checked .ant-checkbox-inner::after{
                    border-color: #e97771;
                }
                ::v-deep .ant-checkbox-wrapper{
                    font-size: 2.2vh;
                }
            }
            .member{
                display: flex;
                font-size: 1.8vh;
                padding: 2vh 1.5vw;
                border-top: 1px solid #e5e5e5;
                border-bottom: 1px solid #e5e5e5;
                margin-top: 20vh;
                .img{
                    width: 10vh;
                    height: 10vh;
                    img{
                        width: 100%;
                        height: 100%;
                        border-radius: 50%;
                    }
                }
                .userInfo{
                    flex: 1;
                    padding: 1vh 2vw;
                    .photo{
                        font-size: 2vh;
                        margin-bottom: 1vh;
                        span{
                            padding-left: 2vh;
                        }
                    }
                    .card_no{
                        color: #a6a6a6;
                    }
                }
                .btnHandle{
                    .smallClearBtn{
                        margin: 1vh 0;
                    }
                }
            }
        }
    }
}
</style>