<template>
    <a-modal title="临时商品" :visible="showModal" @ok="handleOk" @cancel="handleCancel" width="40%">
        <template slot="footer">
            <div class="bottomBtn">
                <a-button class="clearBtn" @click="handleCancel">关闭</a-button>
                <a-button class="OkBtn" @click="handleOk">确定</a-button>
            </div>
        </template>
        <div class="bigForm">
            <a-form-model  :model="form"  :rules="rules" ref="ruleForm" :label-col="{ span: 7 }" :wrapper-col="{ span: 15 }" >
                <a-form-model-item label="商品名称" prop="goods_name">
                    <a-input class="input" v-model="form.goods_name" placeholder="临时商品" ref="goods_name" @focus="focushandle('goods_name')"></a-input>
                </a-form-model-item>
                <a-form-model-item label="单价" prop="goods_price">
                    <a-input class="input" v-model="form.goods_price" placeholder="请输入价格" ref="goods_price" @focus="focushandle('goods_price')"></a-input>
                </a-form-model-item>
                <a-form-model-item label="计量" prop="total_num">
                    <a-input class="input" v-model="form.total_num" placeholder="请输入数量" ref="total_num" @focus="focushandle('total_num')"></a-input>
                </a-form-model-item>
                <a-form-model-item label="打印机名称" prop="printer_id">
                    <a-select  v-model="form.printer_id" placeholder="请选择">
                        <a-select-option :value="item.printer_id" v-for="(item,index) in printList" :key="index">
                            {{item.printer_name}}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
            </a-form-model>
        </div>
    </a-modal>
</template>
<script>
import { mapState , mapMutations } from "vuex";
import {create} from '@/request/business.js';
import { list } from "@/request/api";
export default {
    props:{
        showModal:{
            default:false,
            type:Boolean
        },
    },
    data() {
        return {
            form: {       
                goods_name:'',
                goods_price:'',
                total_num:"1",
                printer_id:'',
            },
            rules: {
                total_num: [
                    { required: true,  trigger: "change" ,message: "请输大于0的整数",},
                    { pattern:  /^\+?[0-9][0-9]*$/, message: "请输大于0的整数", trigger: "change" },
                ],
                //   goods_name:[{required: true,message: "请填写菜品名称",}],
                goods_price:[{required: true,message: "请输入单价",}],
                // printer_id:[{required: true,message: "请选择打印机",trigger: 'change'}],
            },
            printList:[],//打印机列表 
        };
    },
    computed: {
        ...mapState({
            cartList:state=>state.fast_food.cartList,
        }),
    },
    watch:{
        showModal(val){
            if(val){
                this.printListRequest()
            }else{
                this.$refs.ruleForm.resetFields();
            }
        },
        'form.goods_price'(val){
            this.$set(this.form , 'goods_price' ,  this.getTofixed(val))
        },
        'form.total_num'(val){
            this.$set(this.form , 'total_num' ,  this.getInteger(val))
        },
    },
    methods: {
        ...mapMutations(['set_cartList']),
        focushandle(key){
            this.$refs[key].select();
        },
        handleOk(){   //点击确定按钮 
            const _this=this;
            this.$refs.ruleForm.validate(valid => {
                if (valid) {
                    _this.createRequest();
                }
            })
        },
        handleCancel(){ //点击取消按钮
            this.$emit('cancel',false);
        },
        createRequest(){
            const _this=this;
            if(!this.form.goods_name){
                this.form.goods_name = '临时商品'
            }
            localStorage.setItem('LSSP_printer_id' , this.form.printer_id)
            create(this.form).then((res) => {       
                if(res.data.status===200){
                    console.log(res);
                    let data = res.data.data
                    data.goods_original_price = data.goods_price
                    let list = JSON.parse(JSON.stringify(this.cartList)) || []
                    list.unshift(data)
                    this.set_cartList(list)
                    _this.$emit('cancel',false)//关闭按钮                    
                }           
            })
        },
        printListRequest(){
            list({page:0, status:0}).then((response)=>{
                const oldList = response.data.data.list.data;
                this.printList=oldList;
                this.$set(this.form , 'printer_id' , Number(localStorage.getItem('LSSP_printer_id')) || null)
            })
        }
    }, 
};
</script>
<style lang="less" scoped>
.bottomBtn{
    padding: 1vh 8vw;
    display: flex;
    justify-content: space-around;
}
::v-deep .ant-form-item-label{
    line-height: 6vh;
}
::v-deep .ant-select-selection-selected-value{
    font-size: 1.8vh;
}
</style>
