<template>
    <div class="member">
        <!-- 会员 会员查询 会员注册 会员详情 -->
        <myDrawer :visible="showDrawer" :title="titleText" :width="56" @close="close">
            <div v-if="infoPage ==  1">
                <div class="bigForm radio">
                    <a-radio-group button-style="solid" v-model="tabVal">
                        <a-radio-button value="1">会员查询</a-radio-button>
                        <a-radio-button value="2">会员注册</a-radio-button>
                    </a-radio-group>
                </div>
                <div v-if="tabVal == 1">
                    <div class="bigForm">
                        <a-input placeholder="请输入手机号、卡号、姓名进行查询" v-model="user" @pressEnter="okHandle('search')">
                            <div class="textColor" slot="addonAfter" @click="okHandle('search')">查询</div>
                        </a-input>
                    </div>
                    <div  :style="'height:' + height + 'vh;margin-top:5vh;'">
                        <numCode :height="height" v-model="user" @okHandle="okHandle('search')"></numCode>
                    </div>
                </div>
                <div v-else class="bigForm">
                    <a-form-model ref="ruleForm" :model="form" :rules="rules" :label-col="{ span: 5 }" :wrapper-col="{ span: 16 }">
                        <a-form-model-item label="会员姓名" prop="nick_name">
                            <a-input v-model="form.nick_name"/>
                        </a-form-model-item>
                        <a-form-model-item label="手机号码" prop="mobile">
                            <a-input v-model="form.mobile"/>
                        </a-form-model-item>
                        <a-form-model-item label="会员卡号" prop="card_number">
                            <a-input v-model="form.card_number"/>
                        </a-form-model-item>
                        <a-form-model-item label="会员生日" prop="birthday">
                            <a-date-picker v-model="form.birthday" style="width:100%;" valueFormat="YYYY-MM-DD" placeholder=""/>
                        </a-form-model-item>
                        <a-form-model-item label="会员性别" prop="gender">
                            <a-select v-model="form.gender">
                                <a-select-option value="0"><div style="height:5vh;line-height:5vh;font-size:1.8vh;">未知</div></a-select-option>
                                <a-select-option value="1"><div style="height:5vh;line-height:5vh;font-size:1.8vh;">男</div></a-select-option>
                                <a-select-option value="2"><div style="height:5vh;line-height:5vh;font-size:1.8vh;">女</div></a-select-option>
                            </a-select>
                        </a-form-model-item>
                    </a-form-model>
                    <div style="height:12vh"></div>
                    <div class="bottomBtn">
                        <a-button class="clearBtn" @click="close">关闭</a-button>
                        <a-button class="OkBtn" @click="okHandle('register')">确定</a-button>
                    </div>
                </div>
            </div>
            <div v-else class="detail">
                <div class="info">
                    <div class="img">
                        <img :src="currentMember.avatar_url" v-if="currentMember.avatar_url != null" alt="">
                        <img src="../../../assets/default-avatar.png" v-else alt="">
                    </div>
                    <div class="userInfo">
                        <div class="mobile">
                            <b>{{currentMember.mobile}}</b>
                            <span>{{currentMember.nick_name}}</span>
                        </div>
                        <div class="card_no">生日: {{currentMember.birthday || '-'}}</div>
                        <div class="card_no">卡号: {{currentMember.card_number || '-'}}</div>
                    </div>
                    <div class="btnHandle">
                        <a-button class="smallClearBtn" @click="cancelMember">取消会员</a-button><br>
                        <a-button class="smallClearBtn" @click="onChange">更换会员</a-button>
                    </div>
                </div>
                <div class="card_info">
                    <div class="left">
                        <div>VIP等级</div>
                        <div class="quanyi">
                            <div>{{(currentMember.grade && currentMember.grade.name) || '-'}}</div>
                            <div>{{(currentMember.grade && currentMember.grade.equity && currentMember.grade.equity.discount) || '-'}}折</div>
                        </div>
                    </div>
                    <div class="left">
                        <div>会员余额</div>
                        <div class="quanyi">
                            <div>￥{{currentMember.balance}}</div>
                            <div>
                                <a-button class="smallClearBtn" @click="openRecharge">立即充值</a-button>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="zuijin">最近消费记录</div>
                <a-table :columns="columns" :data-source="currentMember.RecordList || []" :pagination="false" bordered :rowKey="
                    (record, index) => {
                    return index;
                    }
                "></a-table>
                <div style="height:12vh"></div>
                <div class="bottomBtn">
                    <a-button class="clearBtn" @click="close">关闭</a-button>
                    <a-button class="OkBtn" @click="okHandle('check')">确定</a-button>
                </div>
            </div>
            
        </myDrawer>
    </div>
</template>
<script>
import myDrawer from '@/components/my-drower/my-drawer.vue'
import numCode from '@/components/keycode/fastFoodNumCode.vue'
import { search , UserBill , addMember } from "@/request/mis";
import { mapState , mapMutations } from "vuex";
import { orderUser , OrderDetail } from "@/request/fast_food_settle";
export default {
    name:'member',
    components:{ myDrawer , numCode },
    props:{
        showDrawer:{
            type:Boolean,
            default:false
        },
        isSettle:{
            type:Boolean,
            default:false
        },
        fromHuiyuanPay:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            tabVal: '1',
            user:'',
            height:50,
            form:{},
            rules:{
                mobile: [
                    { required: true, message: '请输入手机号', trigger: 'change' },
                    { pattern : /^1[3-9]{1}[0-9]{9}$/, message: '电话格式不正确', trigger: 'blur' }
                ],
            },
            titleText:'会员',
            infoPage: 1,
            columns:[
                {
                    title: '姓名',
                    dataIndex: 'nick_name',
                    
                },{
                    title: '时间',
                    dataIndex: 'pay_time',
                    width:'26%'
                },{
                    title: '支付方式',
                    dataIndex: 'pay_type',
                },{
                    title: '交易进账',
                    dataIndex: 'pay_price',
                },{
                    title: '动作/备注',
                    dataIndex: 'remark',
                },{
                    title: '结果',
                    dataIndex: 'pay_status',
                }
            ],
            // userInfo:{},
            // tableData:[]
        }
    },
    watch:{
        showDrawer(val){
            if(this.isSettle){
                this.infoPage = 1
            }else{
                this.infoPage = (this.currentMember && this.currentMember.user_id) ? 2 : 1
            }
        }
    },
    computed:{
        ...mapState({
            currentMember:state=>state.fast_food.currentMember,
            orderInfo:state=>state.fast_food.orderInfo,
        }),
    },
    methods:{
        ...mapMutations(['set_currentMember','set_orderInfo']),
        close(){
            this.onChange()
            this.$emit('closeHandle')
        },
        okHandle(type){
            if(type == 'search'){ //查询会员
                search({keywords:this.user}).then(res => {
                    if(res.data.status === 200){
                        // this.userInfo = res.data.data
                        UserBill({keywords:this.user,page:1,listRows:5}).then(resq => {
                            if (resq.data.status == 200) {
                                this.infoPage = 2
                                // this.tableData = resq.data.data.list
                                this.set_currentMember({
                                    ...res.data.data,
                                    RecordList:resq.data.data.list
                                })
                                if(this.orderInfo && this.orderInfo.foundingLogId){
                                    orderUser({
                                        keywords:this.currentMember.mobile,
                                        founding_log_id:this.orderInfo.foundingLogId,
                                        select_type:1
                                    }).then(res => {

                                    })
                                }
                            }
                        })
                    }
                })
                
            }else if(type == 'register'){ //注册会员
                this.$refs.ruleForm.validate(valid => {
                    if (valid) {
                        addMember(this.form).then(res => {
                            if(res.data.status === 200){
                                this.user = this.form.mobile
                                this.okHandle('search')
                            }
                        })
                    }
                });
            }else if(type === 'check'){
                if(this.isSettle){
                    this.$emit('okHandle','openSettle')
                }else if(this.fromHuiyuanPay){
                    OrderDetail({foundingLoId : this.orderInfo.foundingLogId}).then(res => {
                        if(res.data.status === 200){
                            this.set_orderInfo({
                                ...res.data.data,
                                foundingLogId:res.data.data.founding_log_id
                            })
                            this.$emit('okHandle','openHuiyuanPay')
                        }
                    })
                    
                }else{
                    this.close()
                }
                
            }
        },
        cancelMember(){
            if(this.orderInfo && this.orderInfo.foundingLogId){
                orderUser({
                    keywords:this.currentMember.mobile,
                    founding_log_id:this.orderInfo.foundingLogId,
                    select_type:2
                }).then( res=> {
                    if(res.data.status == 200){
                        this.set_currentMember({})
                    }
                })
            }else{
                this.set_currentMember({})
            }
            this.onChange()
        },
        onChange(){
            this.infoPage = 1
            this.form = {}
            this.user = ''
        },
        openRecharge(){
            this.$emit('okHandle','openRecharge')
        }
    }
}
</script>
<style lang="less" scoped>
    ::v-deep .ant-table{
        th , td{
            font-size: 1.6vh;
            padding: 2vh 1vw;
        }
        td:nth-child(2){
            padding: 2vh 0.5vw;
        }
    } 
    .radio{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 3vh;
    }
    .ant-form{
        padding: 2vh 0;
    }
    ::v-deep .ant-form-item{
        margin-bottom: 5vh;
        .ant-form-item-label label{
            font-size: 2.2vh;
            line-height: 6vh;
            padding-right: 1vw;
        }
    }
    .bottomBtn{
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        border-top: 1px solid #e8e8e8;
        background: #ffffff;
        padding: 3vh 12vw;
        display: flex;
        justify-content: space-around;
    }
    .detail{
        color: #505050;
        .info{
            display: flex;
            font-size: 1.8vh;
            .img{
                width: 10vh;
                height: 10vh;
                img{
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }
            .userInfo{
                flex: 1;
                padding: 1vh 2vw;
                .mobile{
                    font-size: 2vh;
                    margin-bottom: 1vh;
                    line-height: 2.5vh;
                    display: flex;
                    align-items: center;
                    span{
                        padding-left: 1.5vw;
                        max-width: 22vw;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
                .card_no{
                    color: #a6a6a6;
                }
            }
            .btnHandle{
                .smallClearBtn{
                    margin: 1vh 0;
                }
            }
        }
        .card_info{
            background: #eee;
            display: flex;
            margin-top: 1.5vh;
            .left{
                margin: 2vh;
                background: #ffffff;
                flex: 1;
                padding: 1.5vh;
                .quanyi{
                    margin-top: 2vh;
                    display: flex;
                    justify-content: space-between;
                    color: #d95e58;
                    font-weight: 700;
                    font-size: 2.6vh;
                }
            }
        }
        .zuijin{
            margin: 1.5vh 0 1vh;
            font-size: 2.1vh;
            font-weight: 700;
        }
    }
</style>