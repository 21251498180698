<template>
    <div class="pay_saoma">
        <!-- 扫码结算 -->
        <myDrawer :visible="showDrawer" :width="68" @close="close" :bodyStyle="{padding:0}">
            <div class="saoma" v-if="showDrawer">
                <div class="title">
                    <div class="back" @click="goSettlePage">
                        <span class="iconfont icon-fanhui" v-if="!showSuccess"></span>
                        <div v-if="!showSuccess">返回结算页</div>
                    </div>
                    <div class="title_name">扫码支付</div>
                    <div style="width: 20vw;"></div>
                </div>
                <!-- 支付成功 -->
                <div class="success_box" v-if="showSuccess">
                    <span class="iconfont icon-xiaolian" :style="pay_Finish ? 'color: #28ac3c;' : 'color:#2a82e4'"></span>
                    <div class="payMoney">扫码支付￥{{money}}</div>
                    <div class="wancheng bgColor" v-if="pay_Finish">完成结算</div>
                    <div v-else class="last">
                        <div class="xiaoji checkedbgc">
                            <div>
                                <div>应收</div>
                                <div class="xiaoji1">￥{{orderInfo.order_price}}</div>
                            </div>
                            <div>
                                <div>待收</div>
                                <div class="xiaoji2">￥{{orderInfo.nopay_price}}</div>
                            </div>
                            <div>
                                <div>优惠</div>
                                <div class="xiaoji3">￥{{(orderInfo.total_price - orderInfo.order_price).toFixed(2)}}</div>
                            </div>
                        </div>
                        <div class="last_btn">
                            <a-button class="btn" @click="goSettlePage">返回结算页</a-button>
                            <a-button class="btn"  @click="goSettlePage('xianjin')">现金支付</a-button>
                        </div>
                    </div>
                </div>
                <!-- 支付 -->
                <div class="right_box" v-else>
                    <div class="xiaoji checkedbgc">
                        <div>
                            <div>应收</div>
                            <div class="xiaoji1">￥{{orderInfo.order_price}}</div>
                        </div>
                        <div>
                            <div>待收</div>
                            <div class="xiaoji2">￥{{orderInfo.nopay_price}}</div>
                        </div>
                        <div>
                            <div>优惠</div>
                            <div class="xiaoji3">￥{{(orderInfo.total_price - orderInfo.order_price).toFixed(2)}}</div>
                        </div>
                    </div>
                    <div class="input_box">
                        <div class="input_l">扫码金额：</div>
                        <a-input class="input_c" ref="input_c" v-model="money" :disabled="!showCode"  @pressEnter="okHandle"
                         @select="focus_select = true" @click="focus_select = false" @focus="focushandle"/>
                        <a-popover trigger="click" :visible="showCode" placement="bottom">
                            <div slot="content" style="width:30vw;">
                                <numCode v-model="money"  @okHandle="okHandle" :focus_select="focus_select"></numCode>
                            </div>
                            <div class="input_r" @click="changeMoney">修改金额</div>
                        </a-popover>
                        
                    </div>
                    <div class="img_box">
                        <a-input ref="auth_no" v-model="auth_no" class="input" @blur="inputBlur"/>
                        <img src="../../../assets/sao.png" alt="">
                    </div>
                </div>
            </div>
        </myDrawer>
    </div>
</template>
<script>
import myDrawer from '@/components/my-drower/my-drawer.vue'
import numCode from '@/components/keycode/fastFoodNumCode.vue'
import { mapState , mapMutations } from "vuex";
import { orderpay , orderpaystatus , OrderDetail , finish } from "@/request/fast_food_settle";
export default {
    name:'settle',
    components:{ myDrawer , numCode },
    props:{
        showDrawer:{
            type:Boolean,
            default:false
        }
    },
    data(){
        return{
            money:'',
            showCode:false,
            showSuccess:false,
            completePay:false,
            auth_no:'',
            canPay:true,
            oldValue:'',
            focus_select:false,
            pay_Finish:false
        }
    },
    watch:{
        showDrawer(val){
            if(val){
                this.showCode = false
                console.log('123',this.orderInfo.nopay_price);
                this.money = this.orderInfo.nopay_price
                setTimeout(() => {
                    this.$refs.auth_no.focus()
                },100)
                this.getOrderDetail(1)
            }else{
                this.auth_no = ''
                this.showSuccess = false
                this.pay_Finish = false
            }
        },
        money(val){
            if(val * 1 > this.orderInfo.nopay_price * 1){
                this.money = val.slice(0,val.length - 1)
                this.$message.warning('支付金额不能大于待收金额')
            }else{
                this.$set(this , 'money' , this.getTofixed(val))
            }
            this.focus_select = false
        },
        auth_no(val){
            if(val){
                this.canPayHandle(val)
            }
        }
    },
    computed:{
        ...mapState({
            orderInfo:state=>state.fast_food.orderInfo,
        }),
    },
    methods:{
        ...mapMutations(['set_orderInfo','set_currentMember','set_cartList']),
        close(){
            this.$emit('closeHandle')
        },
        changeMoney(){
            this.showCode = true
            setTimeout(() => {
                this.$refs.input_c.focus()
                this.$refs.input_c.select();
            },100)
        },
        okHandle(){
            this.showCode = false
            this.$refs.auth_no.focus()
        },
        inputBlur(){
            if(!this.showSuccess && !this.showCode){
                setTimeout(() => {
                    if(this.showDrawer){
                        this.$refs.auth_no.focus();
                    }
                },50)
            }
        },
        canPayHandle(value){
            if(this.canPay){
                if(this.oldValue == value){
                    this.canPay = false
                    this.payHandle()
                    setTimeout(() => {
                        this.canPay = true
                    },200)
                }else{
                    setTimeout(() => {
                        this.oldValue = value
                        this.canPayHandle()
                    },100)
                }
            }
        },
        payHandle(){
            if(this.money){
                orderpay({
                    foundingLoId:this.orderInfo.foundingLogId,
                    pay_type:'aggregatepay',
                    auth_no: this.auth_no,
                    current_price:this.money
                }).then(res => {
                    if(res.data.status === 200){
                        this.getStatus(res.data.data)
                    }else if(res.data.status===500){
                        this.auth_no = ''
                    }
                })
            }else{
                this.$message.error('请输入支付金额')
            }
            
        },
        getStatus(data){
            orderpaystatus(data).then(res => {
                if(res.data.status === 200){
                    if(res.data.data.data_state == 2){
                        if(res.data.data.status == 2){
                            this.getOrderDetail()
                            if(res.data.data.speech_path){
                                 bound.play(res.data.data.speech_path)
                            }
                        }else{
                            let _this = this
                            this.$confirm({
                                title: "提示",
                                content: "支付失败请重试",
                                okText: "确定",
                                cancelText: "取消",
                                icon: 'exclamation-circle',
                                onOk() {
                                    _this.inputBlur()
                                    _this.auth_no = ''
                                },
                                onCancel() {
                                    _this.goSettlePage()
                                },
                            });
                        }
                        
                    }else{
                        setTimeout(() => {
                            this.getStatus(data)
                        },500)
                    }
                }
            })
        },
        getOrderDetail(val){
            OrderDetail({foundingLoId : this.orderInfo.foundingLogId}).then(res => {
                if(res.data.status === 200){
                    this.set_orderInfo({
                        ...res.data.data,
                        foundingLogId:res.data.data.founding_log_id
                    })
                    if(val == 1){
                        this.money = this.orderInfo.nopay_price
                    }else{
                        this.showSuccess = true
                        if(this.orderInfo.nopay_price == 0){
                            // setTimeout(() => {
                            //     this.FinishOrder()
                            // },500)
                            this.pay_Finish = true
                            this.$message.success('结算完成')
                            this.$emit('FinishOrder')
                        }
                    }
                    
                }
            })
        },
        FinishOrder(){
            finish({foundingLoId:this.orderInfo.foundingLogId}).then(res => {
                if(res.data.status === 200){
                    this.$message.success('结算完成')
                    this.$emit('FinishOrder')
                    this.showSuccess = false
                }
            })
        },
        goSettlePage(str){
            this.$emit('goSettlePage','saoma',str)
        },
        focushandle(){
            this.$refs.input_c.select();
        }
    }
}
</script>
<style lang="less" scoped>
.saoma{
    height: 100vh;
    font-size: 1.8vh;
    color: #505050;
    .title{
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #e5e5e5;
        height: 8vh;
        line-height: 8vh;
        .back{
            width: 20vw;
            margin-left: 2vw;
            color: #d95e58;
            vertical-align: middle;
            display: flex;
            align-items: center;
            cursor: pointer;
            span{
                font-size: 2.8vh;
                margin-right: 1vw;
            }
        }
        .title_name{
            font-size: 2.2vh;
            font-weight: 700;
            text-align: center;
        }
        
        
    }
    .success_box{
        padding: 6vh 9vw;
        text-align: center;
        .icon-xiaolian{
            font-size: 15vh;
        }
        .payMoney{
            font-size: 3vh;
        }
        .wancheng{
            // background: #d95e58;
            color: #ffffff;
            width: 30vw;
            margin: 20vh auto 0;
            height: 8vh;
            line-height: 8vh;
            font-size: 3vh;
            border-radius: 0.6vh;
        }
        .last{
            margin-top: 15vh;
            .xiaoji{
                // background: #ffe8e8;
                display: flex;
                padding: 3vh 2vw;
                font-weight: 700;
                font-size: 2vh;
                border-radius: 0.6vh;
                >div{
                    flex:1;
                    text-align: center;
                    .xiaoji1,.xiaoji2,.xiaoji3{
                        font-size: 2.5vh;
                        color: #57d63f;
                        margin-top: 2vh;
                    }
                    .xiaoji1,.xiaoji2{
                        border-right: 1px solid #e5e5e5;
                        color: #ff6565;
                    }
                    .xiaoji1{
                        color: #808080;
                    }
                }
            }
            .last_btn{
                margin-top: 5vh;
                .btn, .btn:active, .btn:focus, .btn:hover{
                    height: 6vh;
                    line-height: 6vh;
                    width: 24vw;
                    border-color: #ff6565;
                    color: #505050;
                    font-size: 2vh;
                    &:first-child{
                        margin-right: 2vw;
                    }
                }
            }
        }
    }
    .right_box{
        padding: 3vh 1.5vw;
        .xiaoji{
            // background: #ffe8e8;
            display: flex;
            padding: 3vh 2vw;
            font-weight: 700;
            font-size: 2.5vh;
            border-radius: 0.6vh;
            >div{
                flex:1;
                text-align: center;
                .xiaoji1,.xiaoji2,.xiaoji3{
                    font-size: 3.8vh;
                    color: #57d63f;
                    margin-top: 2.6vh;
                }
                .xiaoji1,.xiaoji2{
                    border-right: 1px solid #e5e5e5;
                    color: #ff6565;
                }
                .xiaoji1{
                    color: #808080;
                }
            }
        }
        .input_box{
            height: 7vh;
            padding: 1.5vh 1vw;
            font-size: 2.2vh;
            display: flex;
            border: 1px solid #a6a6a6;
            border-radius: 0.6vh;
            line-height: 4vh;
            margin-top: 3vh;
            .input_c{
                font-size: 2.8vh!important;
                height: 4.2vh;
                font-weight: 700;
                color: #ff6565;
                flex: 1;
                margin: 0 2vw;
                border: none;
                border-radius: 0;
                border-right: 1px solid #a6a6a6;
                
            }
            .ant-input[disabled]{
                background: none;
                cursor: text;
            }
            .input_r{
                color: #2a82e4;
                cursor: pointer;
            }
        }
        .img_box{
            // text-align: center;
            // img{
            //     height: 48vh;
            // }
            position: relative;
            height: 48vh;
            .input{
                width: 10vw;
                margin: 5vh 5vw;
            }
            img{
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                background: #ffffff;
                z-index: 999;
            }
        }
    }
}
</style>