<template>
    <div class="practice">
        <!-- 做法  口味  忌口 规格 称重 -->
        <a-modal :title="weightGoods.goods_name" :visible="showModal" @cancel="handleCancel" width="50vw" :maskClosable="false">
            <div class="practice_box">
                <div v-if="weightGoods.is_weighing == 2">
                    <span class="title">零售价：</span>
                    <span class="options xiaoji">
                        <span>￥{{goods_price}}</span>
                        <b class="textColor">小计：{{(goods_price * 1 * weightNum).toFixed(2)}}</b>
                    </span>
                </div>
                <div v-else>
                    <span class="title">零售价：</span>
                    <span class="options">
                        ￥{{goods_price}}
                    </span>
                </div>
                
                <div v-if="weightGoods.is_weighing == 2">
                    <span class="title">重量：</span>
                    <span class="options">
                        <a-input class="inp" v-model="weightNum" suffix="| KG" :disabled="disabled"/>
                    </span>
                </div>
                <div v-if="guigeArr && guigeArr.length">
                    <div class="title">规格：</div>
                    <div class="options">
                        <a-button :class="guige == item.spec_name ? 'smallOkBtn' : 'smallClearBtn'" v-for="(item,i) in guigeArr" :key="i" @click="guige = item.spec_name;goods_price=item.spec_price.toFixed(2)">{{item.spec_name}}</a-button>
                    </div>
                </div>
                <div v-if="zuofaArr && zuofaArr.length">
                    <div class="title">做法：</div>
                    <div class="options">
                        <a-button :class="zuofa == item ? 'smallOkBtn' : 'smallClearBtn'" v-for="(item,i) in zuofaArr" :key="i" @click="zuofa = item">{{item}}</a-button>
                    </div>
                </div>
                <div v-if="kouweiArr && kouweiArr.length">
                    <div class="title">口味：</div>
                    <div class="options">
                        <a-button :class="kouwei == item ? 'smallOkBtn' : 'smallClearBtn'" v-for="(item,i) in kouweiArr" :key="i" @click="kouwei = item">{{item}}</a-button>
                    </div>
                </div>
                <div v-if="jikouArr && jikouArr.length">
                    <div class="title">忌口：</div>
                    <div class="options">
                        <a-button :class="jikou.findIndex(ele => ele == item ) > -1 ? 'smallOkBtn' : 'smallClearBtn'" v-for="(item,i) in jikouArr" :key="i" @click="jikouHandle(item)">{{item}}</a-button>
                    </div>
                </div>
            </div>
            <template slot="footer">
                <div :style="'height:' + height + 'vh;'" v-if="weightGoods.is_weighing == 2">
                    <numCode :height="height" v-model="weightNum" @okHandle="handleOk"></numCode>
                </div>
                <div class="bottomBtn" v-else>
                    <a-button class="clearBtn" @click="handleCancel">关闭</a-button>
                    <a-button class="OkBtn" @click="handleOk">确定</a-button>
                </div>
            </template>
        </a-modal>
    </div>
</template>
<script>
import numCode from '@/components/keycode/fastFoodNumCode.vue'
import { mapState , mapMutations } from "vuex";
export default {
    name:'practice',
    props:{
        showModal:{
            default:false,
            type:Boolean
        },
    },
    components:{ numCode },
    data(){
        return{
            weightNum:'',
            zuofaArr:[],
            kouweiArr:[],
            jikouArr:[],
            zuofa:'',
            kouwei:'',
            jikou:[],
            guigeArr:[],
            guige:'',
            goods_price:'',
            height:32,
            times:"",
            // fina:"",
            disabled:false,
            cheng:"",//称的重量
        }
    },
    watch:{
        showModal(val){
            if(val){
                console.log(this.weightGoods);
                this.zuofaArr = this.weightGoods.practice ? this.weightGoods.practice.split(',') : []
                this.kouweiArr = this.weightGoods.taste ? this.weightGoods.taste.split(',') : []
                this.jikouArr = this.weightGoods.avoid ? this.weightGoods.avoid.split(',') : []
                this.guigeArr = this.weightGoods.spec_content ? JSON.parse(this.weightGoods.spec_content) : []
                this.goods_price = this.weightGoods.goods_price
                this.guige = this.weightGoods.guige || ''
                const instllObject= JSON.parse(localStorage.getItem('install'))?JSON.parse(localStorage.getItem('install')):{use:2,factory:undefined,type:undefined,com:undefined,potter:undefined,}
                if(instllObject.use==1 && this.weightGoods.is_weighing == 2){
                    const com=instllObject.com;
                    const potter=parseFloat(instllObject.potter);
                    const a=bound.getWeight(com, potter).replace(/[\x00-\x1f]+/g, '');
                    const b=(parseFloat(String(a).replace(/[^0-9,.]/ig, ""))).toFixed(2);
                    // const b=this.calculate(instllObject.factory,a)
                    if(b && b !=='NaN' && b !== 'undefined' && b !== 'null'   ){
                        if(a.indexOf("-")!=-1){
                            this.weightNum=-Number(b)
                        }else{
                            this.weightNum=b;
                        }
                    }else{
                        this.weightNum=0.00
                    }
                    this.times=setInterval(() => {
                            setTimeout( 
                            this.weight(com,potter,instllObject.factory)
                            , 0)           
                    },200);
                }
            }else{
                this.zuofa = ''
                this.kouwei = ''
                this.jikou = []
                this.guige = ''
                this.weightNum = ''
            }
        },
        weightNum(val){
            const instllObject= JSON.parse(localStorage.getItem('install'))?JSON.parse(localStorage.getItem('install')):{use:2,factory:undefined,type:undefined,com:undefined,potter:undefined,}
            if(instllObject.use==2){
                this.$set(this , 'weightNum' , this.getTofixed(val))
            }
        },
    },
    computed: {
        ...mapState({
            cartList:state=>state.fast_food.cartList,
            weightGoods:state=>state.fast_food.weightGoods,
        }),
    },
    methods:{
        ...mapMutations(['set_weightGoods','set_cartList']),
        handleCancel(){
            this.$emit('closeHandle')
            clearInterval(this.times);
        },
        calculate(factory,a){
            if(factory==1){ //大华
            // alert(factory)
                const c=(parseFloat(String(a).replace(/[^0-9,.]/ig, ""))/1000).toFixed(2);
                // alert(c)
                return c
            }else if(factory==2){//顶尖
                const c=(parseFloat(String(a).replace(/[^0-9,.]/ig, ""))).toFixed(2);
                // alert(c)
                return c 
            }
        },
        weight(com,potter,factory){
            const a=bound.getWeight(com, potter).replace(/[\x00-\x1f]+/g, '');
            // alert(bound.getWeight(com, potter))
            const b=(parseFloat(String(a).replace(/[^0-9,.]/ig, ""))).toFixed(2);
            // const b=this.calculate(factory,a)
            if(b && b !=='NaN' && b !== 'undefined' && b !== 'null'   ){
                if(a.indexOf("-")!=-1){
                    this.weightNum=-Number(b);
                    this.disabled=true;
                }else{
                    this.weightNum=b;
                    this.disabled=true;
                }
            }else{
                this.weightNum=0.00;
                this.disabled=true;
            }
        },
        handleOk(){
            if(this.guigeArr.length && !this.guige){
                this.$message.error('请选择规格')
            }else if(this.weightGoods.is_weighing == 2 && !this.weightNum){
                this.$message.error('请进行商品称重')
            }else if(this.zuofaArr.length && !this.zuofa && this.kouweiArr.length && !this.kouwei){
                this.$message.error('请选择做法、口味')
            }else if(this.zuofaArr.length && !this.zuofa){
                this.$message.error('请选择做法')
            }else if(this.kouweiArr.length && !this.kouwei){
                this.$message.error('请选择口味')
            }else{
                let weightGoods = JSON.parse(JSON.stringify(this.weightGoods))
                weightGoods.zuofa = this.zuofa
                weightGoods.kouwei = this.kouwei
                weightGoods.guige = this.guige
                weightGoods.goods_price = this.goods_price
                if(this.weightGoods.is_weighing == 2 && this.weightNum){
                    weightGoods.total_num = this.weightNum
                }
                weightGoods.goods_original_price = this.goods_price
                weightGoods.jikou = this.jikou.join(',')
                let list = JSON.parse(JSON.stringify(this.cartList))
                list.unshift(weightGoods)
                this.set_cartList(list)
                this.set_weightGoods({})
                this.handleCancel()
            }
        },
        jikouHandle(item){
            let i = this.jikou.findIndex(ele => ele == item )
            console.log(i)
            if(i > -1){
                this.jikou.splice(i,1)
            }else{
                this.jikou.push(item)
            }
        }
    },
}
</script>
<style lang="less" scoped>
.practice_box{
    >div{
        display: flex;
        align-items: center;
        margin-bottom: 2vh;
        .title{
            font-weight: 700;
            font-size: 2vh;
            width: 7vw;
            text-align: right;
            margin-right: 1vw;
        }
        .options{
            flex: 1;
            .smallOkBtn,.smallClearBtn{
                margin: 0.5vh 1vw 0.5vh 0;
                width: 8.2vw;
                height: 4.6vh;
                text-align: center;
                padding: 0;
            }
        }
        .xiaoji{
            display: flex;
            justify-content: space-between;
        }
        &:last-child{
            margin-bottom: 0;
        }
    }
    
}
.bottomBtn{
    padding: 1vh 8vw;
    display: flex;
    justify-content: space-around;
}
</style>