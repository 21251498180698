<template>
    <div class="orderInvalid">
        <!-- 整单作废 -->
        <a-modal title="整单作废" :visible="showModal" @cancel="handleCancel" width="50vw">
            <div class="invalid_box">
                <span class="iconfont icon-gantanhao"></span>
                <div>{{msg}}<br>是否确定作废？</div>
            </div>
            <template slot="footer">
                <div class="bottomBtn" >
                    <a-button class="clearBtn btn" @click="handleCancel">取消</a-button>
                    <a-button class="OkBtn btn" @click="handleOk">确定作废</a-button>
                </div>
            </template>
        </a-modal>
    </div>
</template>
<script>
import { orderInvalid } from "@/request/fast_food_settle";
import { mapState , mapMutations } from "vuex";
export default {
    name:'orderInvalid',
    props:{
        showModal:{
            default:false,
            type:Boolean
        },
        fromPage:{
            default:'order',
            type:String
        }
    },
    data(){
        return{
            msg:'该操作会清空此单数据，已支付金额原路返回，'
        }
    },
    watch:{
        showModal(val){
            if(val){
                if(this.orderInfo && this.orderInfo.order_type == 2){
                    this.msg = '当前订单为扫码点餐订单，'
                }else{
                    this.msg = '该操作会清空此单数据，已支付金额原路返回，'
                }
            }
        },
    },
    computed:{
        ...mapState({
            orderInfo:state=>state.fast_food.orderInfo,
        }),
    },
    methods:{
        ...mapMutations(['set_currentMember','set_orderInfo' , 'set_cartList','set_pendingOrder']),
        handleOk(){
            if((this.orderInfo && this.orderInfo.paylog && this.orderInfo.paylog.length) || (this.orderInfo && this.orderInfo.order_type == 2)){
                orderInvalid({order_id:this.orderInfo.order_id}).then(res => {
                    if(res.data.status == 200){
                        this.$message.success('订单已作废')
                        this.set_currentMember({})
                        this.set_orderInfo({})
                        this.set_cartList([])
                        if(this.fromPage == 'pending'){
                            this.$emit('voidOrder')
                        }
                        this.$emit('closeOrder')
                    }
                })
            }else{
                this.$message.success('订单已作废')
                this.set_currentMember({})
                this.set_orderInfo({})
                this.set_cartList([])
                if(this.fromPage == 'pending'){
                    this.$emit('voidOrder')
                }
                this.$emit('closeOrder')
            }
            
        },
        handleCancel(){
            this.$emit('closeHandle')
        },

    }
}
</script>
<style lang="less" scoped>
.invalid_box{
    text-align: center;
    color: #505050;
    font-size: 2.2vh;
    padding: 10vh 0;
    span{
        color: #f46464;
        font-size: 10vh;
    }
}
.bottomBtn{
    padding: 1vh 8vw;
    display: flex;
    justify-content: space-around;
    .btn{
        width: 14vw;
        font-weight: 700;
    }
}
::v-deep .ant-modal-close-x .anticon svg{
    color: #d95e58;
}
</style>