<template>
    <div class="recharge">
        <!-- 会员充值 -->
        <a-modal title="会员充值" :visible="showModal" @cancel="handleCancel" width="50vw" :zIndex="1001">
            <div class="recharge_box">
                <div class="info">
                    <div class="img">
                        <img :src="currentMember.avatar_url" v-if="currentMember.avatar_url != null" alt="">
                        <img src="../../../assets/default-avatar.png" v-else alt="">
                    </div>
                    <div class="userInfo">
                        <div class="photo">
                            <b>{{currentMember.mobile}}</b>
                            <span>{{currentMember.nick_name}}</span>
                        </div>
                        <div class="card_no">生日: {{currentMember.birthday}}</div>
                        <div class="card_no">
                            <span class="card_number">卡号: {{currentMember.card_number}}</span>
                            <span class="yue">余额：{{currentMember.balance}}</span>
                        </div>
                    </div>
                </div>
                <div class="payType_row">
                    <span>付款方式：  </span>
                    <a-radio-group v-model="payType">
                        <a-radio value="aggregatepay">扫码支付</a-radio>
                        <a-radio value="cash">现金支付</a-radio>
                    </a-radio-group>
                </div>
                <div class="bigForm radio">
                    <a-radio-group button-style="solid" v-model="tabVal">
                        <a-radio-button value="20">充值套餐</a-radio-button>
                        <a-radio-button value="10">自定义金额</a-radio-button>
                    </a-radio-group>
                </div>
                <div class="list" v-if="tabVal == 20">
                    <div :class="taocan == item.plan_id ? 'taocan checkTaocan' : 'taocan'" v-for="item in comboList" :key="item.plan_id" 
                    @click="taocan = item.plan_id;money = item.money">
                        <div class="name">{{item.plan_name}}</div>
                        <div>充{{parseInt(item.money)}}赠{{parseInt(item.gift_money)}}</div>
                    </div>
                </div>
                <div class="bigForm" v-else>
                    <div class="money">
                        <span>充值金额：</span>
                        <a-input prefix="￥" class="input" v-model="money" placeholder="请输入充值金额"/>
                    </div>
                    <div class="text">手动输入充值金额</div>
                </div>
                
            </div>
            <template slot="footer">
                <div class="bottomBtn">
                    <a-button class="clearBtn" @click="handleCancel">关闭</a-button>
                    <a-button class="OkBtn" @click="handleOk">确定</a-button>
                </div>
            </template>
        </a-modal>
        <a-modal :visible="isShow" title="请扫码" :zIndex="1002" :footer="null" @cancel="isShow = false" width="60vh">
            <div class="saoma">
                <a-input class="input" v-model="auth_no" ref="input_ref" @blur="inputBlur"></a-input>
                <img src="@/assets/sao.png" alt="">
            </div>
        </a-modal>
        <a-modal :visible="showXianjin" :title="payType === 'cash' ? '现金支付' : '扫码支付'" :zIndex="1002" :footer="null" @cancel="showXianjin = false">
            <div>
                <div style="font-size:2.8vh;text-align:center;"><b>￥{{money}}元</b></div>
                <a-result title="支付成功!">
                    <template #icon>
                    <a-icon type="smile" theme="outlined" :style="{ color: '#28ac3c' }"/>
                    </template>
                </a-result>
            </div>
        </a-modal>
    </div>
</template>
<script>
import numCode from '@/components/keycode/fastFoodNumCode.vue'
import { queryRechargePackages , recharge , queryRechargeResult } from "@/request/mis";
import { mapState , mapMutations } from "vuex";
import { search , UserBill } from "@/request/mis";
export default {
    name:'recharge',
    props:{
        showModal:{
            default:false,
            type:Boolean
        },
    },
    components:{ numCode },
    data(){
        return{
            payType:'aggregatepay',
            tabVal:'20',
            taocan:null,
            comboList:[],
            isShow:false,
            auth_no:'',
            showXianjin:false,
            money:'',
            canPay:true,
            oldValue:''
        }
    },
    computed: {
        ...mapState({
            currentMember:state=>state.fast_food.currentMember,
        })
    },
    watch:{
        showModal(val){
            if(val){
                this.getComboList()
            }else{
                this.payType = 'aggregatepay'
                this.tabVal = '20'
                this.money = ''
                this.taocan = null
            }
        },
        isShow(val){
            if(val){
                setTimeout(() => {
                    this.$refs.input_ref.focus();
                    this.auth_no = ''
                },50)
            }
        },
        auth_no(val){
            if(val){
                this.canPayHandle(val)
            }
        },
        money(val){
            this.$set(this , 'money' , this.getTofixed(val))
        }
    },
    methods:{
        ...mapMutations(['set_currentMember']),
        getComboList(){
            queryRechargePackages().then(res => {
                if(res.data.status === 200){
                    this.comboList = res.data.data.list
                }
            })
        },
        handleOk(){
            if(this.money){
                if(this.payType == 'cash'){
                    this.payHandle()
                }else{
                    this.isShow = true
                    console.log('auth_no',this.auth_no);
                }
            }else{
                if(this.tabVal == 20){
                    this.$message.warning('请选择充值套餐')
                }else{
                    this.$message.warning('请输入充值金额')
                }
            }
        },
        canPayHandle(value){
            if(this.canPay){
                if(this.oldValue == value){
                    this.canPay = false
                    this.payHandle()
                    setTimeout(() => {
                        this.canPay = true
                    },200)
                }else{
                    setTimeout(() => {
                        this.oldValue = value
                        this.canPayHandle()
                    },100)
                }
            }
        },
        payHandle(){
            let data = {
                userId:this.currentMember.user_id,//充值会员ID
                target: "balance",//写死balance
                form: {
                    balance: {
                        mode: "inc",//写死inc
                        money: this.money,//充值的金额
                        remark: "",//备注
                        recharge_type: this.tabVal,//充值方式(10自定义金额 20套餐充值)
                        plan_id: this.taocan,//使用的套餐ID，recharge_type为20时必填
                        pay_type: this.payType,//支付类型：alipay（支付宝）wechat（微信）cash（现金）
                        auth_no: this.auth_no,//付款码，微信支付宝时必填
                        promoter_clerk_id:''
                    }
                }
            }
            recharge(data).then(res =>  {
                console.log(res);
                if(res.data.status === 200){
                    this.rechargeRetRequest(res.data.data.recharge_id)
                }else if(res.data.status===500){
                    this.auth_no = ''
                }
            })
        },
        rechargeRetRequest(recharge_id){  //请求查询会员充值结果接口 
            const _this=this;
            queryRechargeResult({recharge_id}).then((res) => {
                if(res.data.status===200){
                    if(res.data.data.data_state == 2){
                        if(res.data.data.status == 2){
                            this.showXianjin = true
                            setTimeout(() => {
                                this.showXianjin = false
                                this.isShow = false
                            },1000)
                            this.set_member()
                            if(res.data.data.speech_path){
                                bound.play(res.data.data.speech_path)
                            }
                        }else{
                            this.$confirm({
                                title: "提示",
                                content: "支付失败请重试",
                                okText: "确定",
                                cancelText: "取消",
                                icon: 'exclamation-circle',
                                zIndex:1003,
                                onOk() {
                                    _this.inputBlur()
                                    _this.auth_no = ''
                                },
                                onCancel() {
                                    _this.isShow = false
                                },
                            });
                        }
                    }else{
                        setTimeout(() => {
                            this.rechargeRetRequest(recharge_id)
                        },500)
                    }
                }
            });
        },
        set_member(){
            search({keywords:(this.currentMember.mobile || this.currentMember.nick_name)}).then(res => {
                if(res.data.status === 200){
                    this.userInfo = res.data.data
                    UserBill({keywords:(this.currentMember.mobile || this.currentMember.nick_name),page:1,listRows:5}).then(resq => {
                        if (resq.data.status == 200) {
                            this.infoPage = 2
                            this.tableData = resq.data.data.list
                            this.set_currentMember({
                                ...res.data.data,
                                RecordList:resq.data.data.list
                            })
                            setTimeout(() => {
                                this.handleCancel()
                            },1000)
                        }
                    })
                }
            })
        },
        handleCancel(){
            this.$emit('closeHandle')
        },
        inputBlur(){
            if(this.isShow){
                setTimeout(() => {
                    this.$refs.input_ref.focus();
                },50)
            }
        }

    }
}
</script>
<style lang="less" scoped>
.recharge_box{
    font-size: 1.8vh;
    .info{
        display: flex;
        margin-bottom: 2vh;
        .img{
            width: 10vh;
            height: 10vh;
            img{
                width: 100%;
                height: 100%;
                border-radius: 50%;
            }
        }
        .userInfo{
            flex: 1;
            padding: 1vh 2vw;
            .photo{
                font-size: 2vh;
                margin-bottom: 1vh;
                display: flex;
                b{
                  min-width: 12vw;  
                }
                span{
                    padding-left: 1.5vw;
                    // max-width: 22vw;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    // white-space: nowrap;
                }
            }
            .card_no{
                color: #a6a6a6;
                display: flex;
                .card_number{
                    min-width: 12vw;  
                }
                .yue{
                    color: #d95e58;
                    padding-left: 1.5vw;
                }
            }
        }
    }
    .payType_row{
        ::v-deep .ant-radio-wrapper{
            font-size: 1.8vh;
        }
        ::v-deep .ant-radio-inner{
            height: 2vh;
            width: 2vh;
            display: flex;
            justify-content: center;
            align-items: center;
            &::after{
                width: 1vh;
                height: 1vh;
                top: 0.4vh;
                left: 0.4vh;
            }
        }
    }
    .radio{
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 3vh;
        .ant-radio-group{
            width: 100%;
        }
        .ant-radio-button-wrapper{
            width: 50%;
            text-align: center;
        }
    }
    .list{
        .taocan{
            display: inline-block;
            width: 30%;
            margin-right: 5%;
            color: #bfbfbf;
            font-size: 2vh;
            border: 1px solid #a6a6a6;
            border-radius: 0.6vh;
            margin-top: 2vh;
            padding: 1.5vh;
            box-sizing: border-box;
            .name{
                font-size: 2.2vh;
                color: #505050;
            }
            &:nth-child(3n){
                margin: 0;
            }
        }
        .checkTaocan{
            color: #ffffff;
            background: #d95e58;
            border-color: #d95e58;
            .name{
                color: #ffffff;
            }
        }
    }
    .money{
        display: flex;
        align-items: center;
        margin-top: 2vh;
        span{
            width: 8vw;
        }
        .input{
            flex: 1;
            ::v-deep .ant-input{
                color: #d95e58;
                font-weight: 700;
            }
        }
    }
    .text{
        padding-left: 10vw;
        color: #bfbfbf;
    }
}
.bottomBtn{
    // border-top: 1px solid #e8e8e8;
    padding: 1vh 8vw;
    display: flex;
    justify-content: space-around;
}
::v-deep .ant-modal-close-x .anticon svg{
    color: #d95e58;
}
.saoma{
    position: relative;
    height: 40vh;
    .input{
        width: 10vw;
        margin: 5vh 5vw;
    }
    img{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        background: #ffffff;
        z-index: 999;
    }
}
</style>